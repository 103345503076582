import { mutate } from "swr";
import util from "./util";

/**
 * Auto mutate $api.post res data to SWR cache layer
 * @next replace it into $api using events!
 */
export default function autoMutate( key, resData ){
  mutators.forEach( _m => {
    const match = key.match( _m.key )
    if( match && match.shift().length === key.length ){
      // console.log('[auto mutate]', key )
      const args = match.slice()
      _m.mutate && _m.mutate( resData, args )
      _m.populates && _m.populates.forEach( ([ _k, _fn ])=> {
        // @ts-ignore
        typeof _k === 'function' && ( _k = _k(resData) )
        if( !_k ) return

        _k = String(_k).replace(/\$0/g, args[0]).replace(/\$1/g, args[1]).replace(/\$2/g, args[2])
        // console.log( 'mutate: ', key + ' --> '+ _k )
        !_fn ? mutate(_k) : mutate( _k, resData, {
          // @ts-ignore
          populateCache: ( _res, _raw ) => _fn( _raw, _res ),
          optimisticData: true,
          revalidate: false,
        })
      })
    }
  })
}

// {
//   key: String | Regex => args[],
//   populates: [
//     [ String | Function(res), Function(raw,res) ]
//   ],
//   mutate: Function( res, args )
// }
const mutators = [
  {
    // @risk resolve/stream is not sync to chat/messages
    key: /\/message\/(\S+?)\/resolve\/input/, // => { ...message }
    populates: [
      [ res => `/chat/${res.cid}/messages`, util.arrObj.upsert ],
    ]
  },
  {
    key: /\/auth\/login\/hfotp/,  // => { ...user }
    populates: [
      ['/auth/me'],
    ]
  },
  {
    key: /\/auth\/login\/email/,  // => { ...user }
    populates: [
      ['/auth/me'],
    ]
  },
  {
    key: /\/auth\/register\/email/,  // => { ...user }
    populates: [
      ['/auth/me'],
    ]
  },
  {
    key: '/user', // => { ...user }
    populates: [
      ['/auth/me', util.arrObj.merge ],
    ]
  },
  {
    key: '/chat', // => { ...chat }
    populates: [
      [ res => `/chat/${ res.id }`, util.arrObj.merge ],
    ]
  },
  {
    key: /\/chat\/(\S+?)\/next/,  // => { messages?, chat? }
    populates: [
      [ res => res.chat && '/chat/$0', (raw,res) => util.arrObj.merge( raw, res.chat ) ],
      [ res => res.messages && '/chat/$0/messages', (raw,res) => util.arrObj.upsert( raw, res.messages ) ],
    ],
  },
  {
    key: /\/track\/(\S+?)\/complete/, // => { id, ...updates }
    populates: [
      [ '/track/$0', util.arrObj.merge ],
      [ '/track/today', util.arrObj.update ],
    ],
  },
  // @fix replace this
  // {
  //   key: '/translate/word', // => tran{}
  //   populates: [
  //     ['/word/count']
  //   ]
  // }
]