import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useSWRImmutable from 'swr/immutable'
import $v from '../../core/values'
import $api from '../../core/api'

import TrackPanel from './components/track-panel';
import TrackCaption from './components/track-caption';
import ReactPlayer from 'react-player/file';
import TrackHeader from './components/track-header';
import { srt2lines, stickLines } from './util';
import { observer } from 'mobx-react-lite'
import _player from './_player'
import Ani from '../../components/ani'
import TrackEnd from './components/track-end'
import util from '../../core/util'
import { TopnavState } from '../../components/topnav'

function Track(){

  const { tid } = useParams()
  const { data: track = { params: {}, r_Track: { srt : '' }, state: -1 } } = useSWRImmutable(`/track/${tid}`)  // @risk signedUrl may expired when user staying at client
  const navigate = useNavigate()
  
  const player = useRef(null)
  const { playing, speed } = _player

  useEffect( ()=>{ _player.reset() },[tid])
  useEffect( ()=>{
    if( track.r_Track.srt !== '' ) _player.set('lines', stickLines( srt2lines( track.r_Track.srt || '' , 0 ) ) )
  }, [track.r_Track.srt])

  useEffect( ()=>{
    if( playing === 2 && track.state === 0 ){
      $api.post(`/track/${track.id}/complete`).then( util.confetti )
    }
  }, [playing,track])

  useEffect(()=>{
    TopnavState.title = track.r_Track.title
    TopnavState.sub = true
  },[track])
  
  //@fix the UI is bad
  return (
    <div className='container'>
      <ReactPlayer ref={ player } config={ { forceAudio: true } } progressInterval = {1} width={0} height={0}
        url={ track.params.signedUrl }
        playing={ playing === 1 }
        playbackRate ={ speed }
        onReady={ () => playing === -2 && window.setTimeout( () => _player.set('playing',-1), 1500 ) }
        onEnded={ () => _player.set('playing',2) }
        onProgress={ ({ playedSeconds }) => _player.set('at', playedSeconds) }
        onDuration={ duration => _player.set('duration', duration) } />


      { playing < 0 &&
        <Ani className='container py-50 center' show={ playing < 0 } type='fadein'>
          <TrackHeader track={ track } />
        </Ani>
      }

      { playing > -1 && playing < 2 &&
        <Ani className='container py-50 center' show={ playing > -1 && playing < 2 } type='fadeup'>
          <TrackCaption />
        </Ani>
      }

      { playing === 2 &&
        <Ani className='container py-10 center' show={ playing === 2 } type='fadeup'>
          <TrackEnd track={ track }
            onNext={ tid=>{ _player.reset() && navigate(`/track/${ tid }`, { replace: true }) } }
            onReplay={ ()=> _player.set('playing',1) } />
        </Ani>
      }

      <Ani className={`track-player fix-b ${ $v.ism ? '' : 'pb-20'}`} show={ playing > -2 } type='fadeup'>
        <TrackPanel player={ player } />
      </Ani>
    </div>
  )

}

export default observer( Track )