import React, { useEffect, useState } from "react"
import Button from "../../../components/button"

import imgC1 from '../../../assets/images/channel-1.jpg'
import imgC2 from '../../../assets/images/channel-2.jpg'
import imgC3 from '../../../assets/images/channel-3.jpg'
import useSWRImmutable from "swr/immutable"
import { installed } from "../../../core/syncExt"

const extUrl = 'https://chromewebstore.google.com/detail/pinidfapmihbmkkieckicbjdpnohnmch'

export default function CardVideos({ className = '', style={} }){

  const [actived, setActived] = useState(true)

  useEffect(()=>{
    setTimeout( () => setActived( installed() ), 2000 )
  },[])

  return  (
    <div className={ className } style={ style }>
      <h2>
        <span className="icon icon-listening mr-5 primary t-4"/>
        <span className="bold t-5 th-4">Holli Tube</span>
      </h2>
      <p className='t-6 silver'>Makes <span className='bold' style={{ letterSpacing:'-.1em' }}>YouTube</span> subtitles better for language learning</p>
      {
        !actived ?
          <div className='mt-20 p-20 round center' style={{ border:'1px #fff2 dashed', background:'#0004' }}>
            <p className="t-5 bold white">Not Installed!</p>
            <p className="t-6 silver">HolliTube enhance <span className='bold' style={{ letterSpacing:'-.1em' }}>YouTube</span> subtitles for langauge learning.</p>
            <div className='mt-20 tapable'
              onClick={ ()=> window.open(extUrl) }>
              <Button text='Get Extension' icons={['chrome']} color={['blue','white']} size={48} />
            </div>
          </div>
          :
          <div className="mt-20 flex-row flex-center" style={{ gap:'20px' }}>
            <p className="flex-1 h-16">
              <span className="inline wh-16 round-full center bg-green mr-5">
                <span className="icon icon-check t-7 th-16 white bold"/>
              </span>
              <span className="t-5 th-16 silver tapable" onClick={ ()=> window.open(extUrl) }>
                <span className="">HolliTube</span> installed!
              </span>
            </p>
            <div className="flex-none">{ <ChannelListButton /> }</div>
            <div className='flex-none'>
              <Button text='Go Youtube' icons={['youtube','open']} color={['blue','white']} size={48}
                onClick={ ()=> window.open('https://youtube.com')} />
            </div>
          </div>
      }
    </div>
  )
}

const ChannelListButton = ()=>{
  const { data : me = {} } = useSWRImmutable('/auth/me')
  const langNative = me.settings?.language_native || 'en'
  return (
    <a className="inline h-48 px-10 bg-lighten round-full center right tapable"
      href={`https://holli.cc/${langNative}/channels`} target="_blank" rel="noreferrer">
      <div className="inline ml-5" style={{ paddingTop:'12px' }}>
        <img className="wh-24 round-full border-ink" alt="channel headicon" style={{  }} src={ imgC3 } />
        <img className="wh-24 round-full border-ink" alt="channel headicon" style={{ marginLeft:'-8px' }} src={ imgC1 } />
        <img className="wh-24 round-full border-ink" alt="channel headicon" style={{ marginLeft:'-8px' }} src={ imgC2 } />
      </div>
      <span className="t-6 bold silver th-48 ml-5">Top Channels</span>
      <span className="icon icon-open gray ml-5 th-48"/>
    </a>
  )
}