import { useEffect, useState } from "react"
import $event from "../core/event"

/**
 * 
 * @param {*} key 
 * @param {*} preset 
 * @returns {[ any, Function ]}
 */
function useEventState( key, preset = {} )
{
  const [params, setParams] = useState(preset)
  useEffect(()=>{
    const unsubscribe = $event.add(key, _v => { setParams(_v) })
    return () => unsubscribe()
  },[key])
  return [params, _p => $event.emit(key, _p) ]
}

const useEvent = (key, cb, deps=[] )=>{
  useEffect(()=>{
    const _d = $event.add( key, cb )
    return () => _d()
  },[key,cb])
}

const fireEvent = (key, resolver) => {
  return $event.emit( key,
    typeof resolver === 'function' ? resolver($event.lastEmits[key])
      : resolver
  )
}
export { useEvent, useEventState, fireEvent }