/**
 * Used in client, pages
 * @param data 
 * @param delay 
 */
export default function syncExt(data, isRetry = false){
  // console.log('[syncExt]', data)
  const dom = document.querySelector('#ext-syncer')
  if( !dom ){
    !isRetry ? setTimeout( ()=>syncExt(data,true), 2000 ) : console.error('[syncExt] ext-syncer not found')
  }else{
    dom.setAttribute( 'data-data', JSON.stringify(data) );
    // @ts-ignore
    setTimeout( ()=> dom.click(), 0 )
  }
}

const installed = ()=>{
  return !!document.querySelector('#ext-syncer')
}

export { installed }