// gist - 20201219

/**
 * - 构造时传入observable data 可实现基于Mobx
 */
export default class Kebab{

  data = []
  pkey = 'id'
  onChange = ( $kebab, event ) => null

  options = {
    maxLength : 200
  }

  constructor( data = [], pkey = 'id', options = {}, onChange = ()=>{} ){
    data && ( this.data = data )
    pkey && ( this.pkey = pkey )
    onChange && ( this.onChange = onChange )
    Object.assign( this.options, options )
  }

  /**
   * 查询
   */
  first( key ){
    return this.data[0] ? ( key ? this.data[0][key] : this.data[0] ) : null
  }
  
  find( id ){
    return this.get({ [this.pkey] : id },1)[0]
  }

  findOrNew( id, data ){
    this.indexOf( id ) < 0 && this.upsert( id, data )
    return this.find(id)
  }

  get( cond = {}, limit = null ){
    let re = []
    this.data.forEach( obj => {
      let match = true
      for( let k in cond ){
        if( obj[k] !== cond[k] ){ match = false; break }
      }
      if( !match || ( limit && re.length >= limit ) ) return
      re.push( obj )
    })
    return re
  }

  sum( k ){
    let _n = 0
    this.data.forEach( obj => { _n += obj[k] || 0 } )
    return _n
  }



  /**
   * 更改
   * @param moveTo -1不移动
   */
  upsert( id, obj, moveTo = -1 ){

    const idx = this.indexOf(id)
    if( moveTo === idx ) moveTo = -1
    obj = Object.assign( {}, this.data[ idx ], obj )
    
    if( idx < 0 ){  //新的
      this.__insert( obj, moveTo )
    }
    else {  //已存在
      if( moveTo >= 0 ) this.__delete( idx ) && this.__insert( obj, moveTo )
      else this.__update( idx, obj )
    }
    this.onChange( this, 'upsert' )
    return this
  }

  update( id , obj, moveTo = -1 ){
    const idx = this.indexOf(id)
    return idx > -1 && this.upsert( id, obj, moveTo )
  }

  __update( idx, obj ){
    this.data[idx] = Object.assign( {}, this.data[ idx ], obj )
    return true
  }
  
  delete( id ){
    const idx = this.indexOf(id)
    this.__delete( idx )
    this.onChange( this, 'delete' )
    return this
  }

  clear(){
    this.data = []
    this.onChange( this, 'clear' )
    return this
  }
  
  __delete( idx ){
    idx >= 0 && this.data.splice( idx, 1 )
    return true
  }

  __insert( obj, idx = -1 ){
    if( idx < 0 ){
      this.data.push( obj )
    }else{
      this.data.splice( idx, 0, obj )
    }
    this.data.length > this.options.maxLength && this.data.pop()
    return true
  }



  /**
   * 内部
   */
  indexOf( id ){
    return this.data.findIndex( obj => obj[this.pkey] === id )
  }

}