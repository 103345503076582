import $api from "../core/api"
import React, { useCallback, useMemo } from "react"
import { openWordBox } from "./box-word"
import useReachBottom from "../hooks/useReachBottom"
import ListStore from "../gist/ListStore"
import { observer } from "mobx-react-lite"

function ListWords({ psize=20, kit='Lookups' }){

  const $list = useMemo( () => {
    const orderBy = kit==='Lookups' ? 'created_at:desc' : 'order:asc'
    const _list = new ListStore( ({ p }) => $api.get(`/word/list/${kit}?p=${p}&psize=${psize}&orderBy=${orderBy}`), { itemKey: 'text' })
    _list.next()
    return _list
  },[psize,kit]) 

  console.log( $list )
  const loadListRef = useReachBottom( $list.next.bind($list), 100 )

  const setWordAcquired = useCallback( (_w,to) =>{
    $api.post('/word/acquired', { wid:_w.wid, lang: _w.lang, text: _w.text, to })
      .then( _o => {
        console.log( { n : _o} )
        $list.items.update( _w.text, { deleted_at: _o.deleted_at })
      }, e => e)
  },[$list])

  // @todo style deleted_at
  return $list.items.data.length === 0 ?
    <div className="py-30 center">
      <div><span className="icon icon-translate gray" style={{ fontSize:'60px' }}/></div>
      <p className='gray t-5 bold'>No words collected</p>
      <p className='white t-6 left mt-50 px-20 py-10 round bg-black'>
        <span className="blue bold mb-5 inline">Auto-Collect</span> words (when you click it to translate) from:
        <br/>- <span className="bold">YouTube Subtitles</span> (with HolliTube extension)
        <br/>- <span className="bold">Holli Chats</span>
      </p>
    </div>
    :
    <div ref={ loadListRef } className='word-list left'>
      { $list.items.data.map( _w =>
        <div key={ _w.lang+'-'+ _w.text }
          className='tapable border-lighten-b py-15 flex-row flex-center hover'
          onClick={ e => openWordBox( _w.text, e.target ) }>
          <p className='flex-none'><span className="t-7 px-5 round bold gray inline mt-5">{ _w.lang?.toUpperCase() }</span></p>
          <p className='th-4 flex-1 pl-10' style={ _w.deleted_at && { textDecoration:'line-through', color:"#777" } }>{ _w.text }</p>
          <p className="flex-2 t-6 gray right">
            { ( _w.translation?.defs?.TRAN || [] ).join(', ') }
          </p>
          <div className="flex-none wh-32 center tapable" onClick={ e => { e.stopPropagation(); setWordAcquired(_w, !_w.deleted_at) } }>
            <span className="th-4 icon icon-delete hover-show gray"/>
          </div>
        </div>
      )}
    </div>
}

export default observer( ListWords )