// gist - 20201219
// edited
import { makeAutoObservable, observable } from "mobx"
import Kebab from "./Kebab"

/**
 * 获取云端list数据
 * - 目前仅支持根据updated_at倒序获取
 * 
 * 基本使用：
 * $list = new List( $api, '/path/list', { psize:5, orderBy:{id:'asc'} } )
 * $list.init( {}, false )
 * $list.next( true ) //加载
 * $list.next( false )  //如已有第一页就不加载
 * 
 * data操作参见 class Kebab
 * 
 * @todo 支持监听Model事件同步值更新
 */

const ListStoreState = {
  items: [],
  p : 0,
  overed : false,
  fetching : false,
  dirty : false,
}

export default class ListStore{

  async fetch( state ){ return [] }

  // options
  options = { itemKey: 'id' }

  // state
  state = observable(ListStoreState)
  items = new Kebab()

  constructor( fetch, options = { itemKey: 'id' } ){
    this.fetch = fetch
    Object.assign( this.options, options )
    this.items = new Kebab( observable([]), this.options.itemKey, {}, _k => {
      console.log( _k.data )
      this.state.dirty = true
    })
    makeAutoObservable(this)
  }

  reset(){
    this.state = ListStoreState
    return this
  }

  /**
   * @param {boolean} load2nd 当为false时且this.items.data
   * - 基于Repo的getter
   */
  async next( load2nd = true ){
    if( this.state.fetching || this.state.overed || ( !load2nd && this.items.data.length > 0 ) ) return Promise.resolve( [] )

    this.state.fetching = true
    return this.fetch( this.state ).then( data => {
      this.state.fetching = false
      if( !data || data.length < 1 ){ //@fix n+1 issue
        this.state.overed = true
        return []
      }else{
        this.items.data.replace( this.items.data.slice().concat( data ) )
        this.state.p++
        return data
      }
    }, e => { this.state.fetching = false; throw e; })
  }

}