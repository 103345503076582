import React, { useEffect } from 'react'
import Ani from '../components/ani'
import ListWords from '../components/list-words'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { TopnavState } from '../components/topnav'

function Vocab(){

  const { kit } = useParams()
  
  useEffect(()=>{
    TopnavState.sub = true
    TopnavState.title = kit.toLocaleUpperCase()
  },[kit])

  return (
    <Ani className='container'>
      <div className='mt-30'>
        <ListWords kit={ kit }/>
      </div>
      <div className='h-160'></div>
    </Ani>
  )
}

export default observer(Vocab)