import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'

import Button from '../components/button'
import Msg from '../components/msg'
import util from '../core/util'
import Loading from '../components/loading'
import $api from '../core/api'
import { fireEvent } from '../hooks/useEvent'

const pb = ( window.innerHeight - ( 64 + 84 + 100 ) ) + 'px'

function Chat(){

  const { cid } = useParams()
  const { data: me = { credits : { daily: 0 } } } = useSWRImmutable('/auth/me')
  const { data: chat = { r_Bot: {} } } = useSWRImmutable(`/chat/${cid}`)
  const { data: messages = [], isLoading: msgLoading } = useSWRImmutable(`/chat/${cid}/messages`, url => {
    return $api.get(url).then( _d => {
      $api.post(`/chat/${cid}/next`)  // call next to get it started!
      return _d
    })
  })
  const { data: usage = { today: 0 } } = useSWRImmutable('/user/usage')
  
  useEffect(()=>{
    messages[0] && fireEvent('/chat/list/', _raw => util.arrObj.upsert( _raw, { id: cid, messages: [messages[0]] } ))
  },[messages, cid])
  // useEffect(()=>{
  //   console.log({ cid, msgLoading })
  //   if( cid && !msgLoading ) $api.post(`/chat/${cid}/next`)
  // },[ cid, msgLoading ])

  const navigate = useNavigate()
  
  // Auto Scroll
  useEffect( ()=>{
    messages && util.scrollToLast('.msg-user', -84)
  }, [ messages ])


  // Party Effect
  useEffect(()=>{
    if( chat?.state===1 ) util.confetti()
  },[chat.state])
  
  return (
    <main className='container py-20' style={{ paddingBottom:pb }}>

      { msgLoading && <div className='ab-tl w-full center' style={{ top:'20vh' }}><Loading /></div> }

      { chat.r_Bot && <p className='center t-6 gray-light th-7 mb-30'>{ chat.r_Bot.name }: { chat.r_Bot.info }</p> }

      <div id='msgs' className={`msgs ani-fadein ${msgLoading ? '' : 'visible' }`}>
        { messages && messages.map( _m =>
          <div key={ _m.id } className="mb-30">
            <Msg data={_m} />
          </div>
        )}
      </div>

      { // Finished
        !msgLoading && chat.state !== 0 &&
        <div className='h-48 left'>
          <Button classes='inline mr-10' size={ 48 } text='Quit' ghost color={['gray-light','silver']} onClick={ ()=> navigate(`/home`, { replace : true }) } />
        { me.credits.daily - usage.today >= chat.dots
          ? <Button classes='inline' size={ 48 } text='Continue' icons={[null,'right']} onClick={ ()=> navigate(`/chat/create/${chat.bot}`, { replace : true }) } />
          : <Button classes='inline' size={ 48 } text='Out of rakes' ghost color={['black','red']} disabled />
        }
        </div>
      }
    </main>
  )

}


export default Chat