const wathersMap = {
  // evname : watchers
}

const $event = {

  lastEmits:{
  
  },

  add(key,cb){
    if( !wathersMap[key] ) wathersMap[key] = []
    wathersMap[key].push(cb)
    const _idx = wathersMap[key].length-1
    return () => wathersMap[key][_idx] = undefined
  },

  emit( key, params){
    $event.lastEmits[key] = params
    if( !wathersMap[key] ){
      // console.log('emit no watchers',key)
    }
    else wathersMap[key]?.forEach( cb => {
      try{ cb && cb(params)}
      catch(e){ console.log(`${key} watcher error`,e) }
    })
  },
  
}

/**
 * Customed Events emitter
 */
// 'keyup' : { event }
document.addEventListener('keypress', e => $event.emit('keypress',e) )
document.addEventListener('keydown', e => $event.emit('keydown',e) )

export default $event