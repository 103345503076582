import React, { useEffect } from 'react'
import Ani from '../components/ani'
import BoxSettings from '../components/box-settings';
import useSWRImmutable from 'swr/immutable';
import $v from "../core/values"
import util from '../core/util';
import toast from '../core/toast';
import { useNavigate } from 'react-router-dom';
import { TopnavState } from '../components/topnav';

function Profile(){

  const { data:me } = useSWRImmutable('/auth/me')
  const navigate = useNavigate()

  useEffect(()=>{
    TopnavState.sub = true
    TopnavState.title = 'Profile'
  },[])
  

  return (
    <Ani className='container left pb-100'>
      {/* Hero */}
      <div className='flex flex-row left'>
        <div className='flex-none'>
          <p className='t-2 th-3' >Hey, { me.nickname }!</p>
          <p className='t-5 mt-10' ><span className='icon icon-email t-6 th-5 mr-5 primary' />{ me.email || 'Not Set' }</p>
          <p className='tapable' onClick={ ()=> util.copyText( me.id ) && toast('User ID copied!') }>
            <span className="t-7 th-4 silver">ID: { me.id }</span>
            <span className='icon icon-copy t-4 th-4 silver ml-5' style={{ verticalAlign:'center' }}/>
          </p>
        </div>
        <div className='flex-1 flex-m-1'></div>
      </div>
        
      <BoxSettings defaultProfile={ me } />

      <div className="mt-30 border-lighten bg-ink round">
        <a href={ $v.twitURL } target="_blank" rel="noreferrer"
          className="p-20 sepline flex-row tapable white">
          <span className="flex-none icon icon-twitter blue t-4 th-5" />
          <p className="flex-1 t-6 th-5 ml-10 blue">Share to Twitter</p>
        </a>
        <a href="mailto:0xhalfong@gmail.com" className="p-20 sepline flex-row tapable white">
          <span className="flex-none icon icon-email t-4 th-5" />
          <p className="flex-1 t-6 th-5 ml-10">Contact Us</p>
        </a>
        <div className="p-20 sepline flex-row tapable" onClick={ ()=> navigate('/gate/logout') }>
          <span className="flex-none icon icon-turnoff t-4 th-5" />
          <p className="flex-1 t-6 th-5 ml-10">Log out</p>
          <p className="flex-none right t-6 th-5 gray"></p>
        </div>
      </div>
    </Ani>
  )

}

export default Profile

/**
  <div className='left'>
    <p className='t-7 th-4 silver'><span className='t-4 bold white mr-5'>6</span>Days Onboarded</p>
    <p className='t-7 th-4 silver mt-5'><span className='t-4 bold white mr-5'>64.7%</span>Attendence</p>
  </div>

  <div className="border-primary flex-center inline ml-10 px-10" style={{ height:'60px', borderRadius:'12px' }}>
    <div className="center">
      <div className='th-5'><span className="t-3 th-5 bold primary mr-5">{ me.dots }</span><Dots /></div>
      <p className="t-7 silver">Total Dots</p>
      <span className="t-3 yellow icon icon-leaf th-48" />
    </div>
  </div>

<div className='mt-20'>
<div className='round border-lighten-primary bg-lighten-primary pl-15 pr-10'>
  <span className='t-7 th-48 bold silver mr-5'>APIKEY</span>
  <span className='t-6 th-48 primary'>{ me.apikey || 'Refresh please!' }</span>
  <div className='fright w-36 h-48 tapable round-full center' onClick={ ()=> util.copyText( me.apikey ) && toast( 'APIKEY Copied!' )  }>
    <span className='icon icon-copy th-48 primary' />
  </div>
  <div className='fright w-36 h-48 tapable round-full center' onClick={ refreshApiKey }>
    <span className='icon icon-refresh th-48 white' />
  </div>
</div>
</div>
*/