import React, { useEffect, useState } from "react"
import { useEvent } from "../hooks/useEvent"

const __getEmptyIndex = letters => {
  const _idx = letters.findIndex( _l => !_l )
  return _idx < 0 ? letters.length - 1 : _idx
}

/**
 * @params { (inputed:string[])=> Promise<boolean> } onChange
 */
export default function InputLetters({ letters = [], onChange }){
  
  const [cursor,setCursor] = useState(0)
  
  useEffect(()=>{ setCursor( __getEmptyIndex( letters ) ) },[letters])
  
  // Input and Onchange
  useEvent('keydown', e =>{
    const _ls = letters.slice()
    // Input
    if( e.key.match(/^\w$/) ){
      _ls[cursor] = e.key;
      onChange(_ls)
    }
    // Delete
    else if( e.key === 'Backspace' && cursor > 0 ){
      const _idx = ( cursor === _ls.length - 1 && !!_ls[cursor] ) ? cursor : cursor - 1 
      _ls[_idx] = undefined
      onChange(_ls)
    }
  },[letters, cursor])
  
  // console.log({ inputed, letters } )
  return (
    <div className="com-input-letter">
      { letters.map((_l,_i)=>
        <div key={`letter-${_i}`} className={`inline mx-5 center border-primary border-b border-2 ${_i === cursor ? 'border-primary' : 'border-gray' }`}>
          <p className="bold center" style={{ fontSize:'3.6vw', lineHeight:'4vw', width:'3.2vw' }}>{ _l || <span className="silver">-</span>}</p>
        </div>
      )}
    </div>
  )
}