import React, { useEffect } from 'react'

import Ani from '../../components/ani';
import CardVideos from './components/card-videos';
import PageFooter from '../../components/page-footer';
import useSWRImmutable from 'swr/immutable';
import ListChats from '../../components/list-chat';
import { useNavigate } from 'react-router-dom';
import { TopnavState } from '../../components/topnav';

const Games = [
  { name:'Wordle', desc: 'desc' },
  { name:'Scrambles', desc: 'desc' },
]

/**
 * @next get chat bots from remote
 * @fix separate Lookups
 */
function Home(){

  //@fix not change when called the `/translate/word`
  //@todo Maybe add useApi('/word/count') and $api.change('/word/count', v=>v+1)
  const { data : vocabs = [] } = useSWRImmutable('/vocab/list')
  const navigate = useNavigate()
  // const vocab_lookup = { kit:'Lookups', count: countLookups.total , desc:'Words you looked up within holli.cc or HolliTube.' }

  useEffect(()=>{
    TopnavState.sub = false
    TopnavState.title = null
  },[])
  
  return (
    <Ani className='container pb-100 left'>

      {/* <header className='center my-80'>
        <h1 className='bold' style={{ fontSize:'2.1em', letterSpacing:'-0.02em' }}>
          <span className='border-primary border-b' style={{borderBottomWidth:'4px' }}>Language</span> Learning Gym
        </h1>
      </header> */}

      {/* <CardChat className='pt-30' /> */}

      {/* Videos */}
      <CardVideos className='mt-30 bg-ink border-lighten p-20 round'/>

      {/*Vocab Rush*/}
      <section className='mt-50'>
        <p><span className="icon icon-vocab primary t-2 mr-10" /><span className='th-2 bold'>Vocabs</span></p>
        <div className='mt-20 grid grid-cols-2 gap-10'>
          {
            vocabs.map( ({ name, kit, length }) =>
              <div key={name} className="border-lighten round tapable bg-ink p-20 flex-row"
                onClick={ navigate.bind(null,`/vocab/${name}`) }>
                <div className='flex-1'>
                  <h3 className='flex-1 t-5 bold'>{ kit }</h3>
                  <p className='t-6 silver'>{ name } (No desc in vocab data)</p>
                </div>
                <p className='flex-none th-24 h-24 px-10 t-6 border-lighten round primary bold'>{ length }</p>
              </div>
            )
          }
        </div>
        

      </section>

      <section className="left mt-50 pl-2">
        <p><span className="icon icon-game primary t-2 mr-10" /><span className='th-2 bold'>Games</span></p>
        <div className='mt-20 grid grid-cols-2 gap-10'>{
          Games.map( ({name,desc}) =>
            <div key={name} className="border-lighten round tapable bg-ink p-20 flex-row"
              onClick={ navigate.bind(null,`/chat/create/${name}`) }>
              <div className='flex-1'>
                <h3 className='flex-1 t-5 bold'>{ name }</h3>
                <p className='t-6 silver'>{ desc }</p>
              </div>
            </div>
          )  
        }</div>
      </section>

      {/* MyWords */}
      {/* <CardMyWords className='mt-30 left bg-ink border-lighten p-20 round' /> */}
      
      {/* Speaking */}
      {/* <div className='mt-30 left bg-ink p-20 round'>
        <h2 className='bold'>Tracks</h2>
        <p className='t-6 silver'>Repeat sentences, not just word</p>
        <TodayTracks className='mt-20' />
      </div> */}

      {/* Listening: Tracks */}
      {/* <div className='mt-50 left'>
        <TodayTracks />
      </div> */}

      <div className='mt-20'>
        <h2 className='t-6 silver'>Recently played</h2>
        <ListChats maxSize={5} psize={5} />
      </div>
      
      <div className='mt-100'><PageFooter /></div>
    </Ani>
  )

}

export default Home