import React, { useMemo } from 'react'
import useReachBottom from '../hooks/useReachBottom'
import { useNavigate } from 'react-router-dom'
import util from '../core/util'
import $api from '../core/api'
import ListStore from '../gist/ListStore'
import { observer } from 'mobx-react-lite'

const ListChats = ({maxSize=1000, psize=10, bot = '' })=>{

  const $list = useMemo( () => {
    const _list = new ListStore( ({ p }) => $api.get(`/chat/list/${bot}?p=${p}&psize=${psize}`))
    _list.next()
    return _list
  },[psize,bot]) 

  const loadListRef = useReachBottom( $list.next.bind($list), 100 )
  const navigate = useNavigate()

  return $list.items.data.length > 0 &&
    <div ref={ loadListRef } className='word-list'>
      { $list.items.data.map( _c =>
        <div key={ _c.id } className='tapable border-lighten-b py-10 flex-row hover'
          onClick={ navigate.bind(null,`/chat/${_c.id}`) }>
          <div className='flex-1'>
            { _c.bot === 'HolliChat' ?
              <p>
                <span className='inline icon icon-chat th-36 mr-10' />
                <span className='t-5 th-36'>{ _c.messages ? _c.messages[0]?.content?.slice(0,30) : 'New Chat' }</span>
                <span className='t-5 th-36 gray'>...</span>
              </p>
              : <p className='inline t-5 th-36 mr-10'>{ _c.bot }</p>
            }
          </div>
          { _c.bot !== 'HolliChat' &&
            <div className='flex-none right mr-10'>
              { _c.state === 1 && <span className='t-7 th-36 icon icon-check green' /> }
              { _c.state === 2 && <span className='t-7 th-36 icon icon-close red' /> }
              { _c.state === 0 && <span className='t-6 th-36 blue'>in progress</span> }
            </div>
          }
          <div className='flex-none right'>
            <p className="inline t-6 th-36 gray">{ util.time.ago(_c.created_at) }</p>
          </div>
        </div>
      )}
    </div>
}


export default observer(ListChats)