import React, { useEffect, useState } from 'react'
import Ani from '../components/ani'
import { useParams } from 'react-router-dom'
import $api from '../core/api'
import InputLetters from '../components/input-letters'
import toast from '../core/toast'
import { useEvent } from '../hooks/useEvent'
import Loading from '../components/loading'

class WordRush{
  kit
  list = []
  psize = 5
  
  /**
   * @return {Promise<{ text:string, [key: string]: any}>} word
   */
  async next(kit){
    // return {
    //   "id": 3122,
    //   "kit": "IELTS",
    //   "lang": "en",
    //   "text": "stain",
    //   "trans": {
    //     "text": "stain",
    //     "from": "en",
    //     "to": "zh-Hans",
    //     "defs": {
    //       "NOUN": [
    //         "污点",
    //         "染色",
    //         "污渍",
    //         "污迹",
    //         "沾污",
    //         "渍",
    //         "着色",
    //         "斑点",
    //         "应变"
    //       ],
    //       "VERB": [
    //         "弄脏",
    //         "玷污"
    //       ]
    //     }
    //   },
    //   "net": {
    //     "related": [
    //       "staining",
    //       "stained",
    //       "stainer",
    //       "stainable"
    //     ],
    //     "antonyms": [
    //       "clean",
    //       "pure",
    //       "spotless",
    //       "unblemished",
    //       "pristine"
    //     ],
    //     "synonyms": [
    //       "blemish",
    //       "spot",
    //       "mark",
    //       "discoloration",
    //       "smudge",
    //       "streak",
    //       "tarnish",
    //       "soil",
    //       "dirty",
    //       "soiled",
    //       "color",
    //       "dye",
    //       "paint",
    //       "imprint",
    //       "impression"
    //     ]
    //   }
    // }
    if( this.kit && this.kit !== kit ) this.list = []
    var _nw = this.list.find( _w => !_w.showed )
    if( !_nw ) await this.__load(kit)
    _nw = this.list.find( _w => !_w.showed )
    _nw.showed = true
    if( !this.list.find( _w => !_w.showed ) ) this.__load(kit)
    return _nw
  }
  async __load(kit){
    const _pg = await $api.post(`/word/${kit}/randPick`,{ to: 'zh-Hans', n:this.psize })
    this.list = this.list.concat(_pg).slice(-100)
  }

  /**
   * Hint first letter, then the whole
   * @return {Array} hinted letters
   */
  hintLetter( _ls = [], full = [] ){
    if( !_ls[0] ) { _ls[0] = full[0] }
    else _ls = full.slice()
    return _ls
  }
  // Hint one more letter
  // hintLetter( _ls = [], full = [] ){
  //   if( !_ls[0] ) { _ls[0] = full[0] }
  //   else if(_ls.findIndex(_l => !_l) > -1){
  //     const validIndexs = _ls.map( (l,i) => !l ? i : -1 ).filter( _i => _i > -1 )
  //     const idx = validIndexs[Math.floor( Math.random() * validIndexs.length )]
  //     _ls[idx] = full[idx]
  //   }
  //   return _ls
  // }

  /**
   * Confirm
   * @return {Number}  ended: 0: pending, 1: ok, 2 :falsed
   */
  confirm(word,_ls=[],hinted = false){
    const _w = word?.text.replace(/[\s-]/g,'') 
    if( _w && _ls.filter(_l => !!_l).length ===  _w.length ){
      const passed = _ls.join('').toLocaleLowerCase() === _w.toLocaleLowerCase()
      return !hinted && passed ? 1 : 2
    }
    return 0
  }
}

const $rush = new WordRush()

function VocabRush(){

  const { kit } = useParams()
  
  const [word,setWord] = useState(null)
  const [letters,setLetters] = useState([]) // The inputed letters
  const [ended,setEnded] = useState(0) // 0: pending, 1: ok, 2 :falsed
  const [hinted,setHinted] = useState(false)
  
  const nextWord = async kit=> {
    console.log('next')
    setEnded(0)
    setHinted(false)
    setWord(null)
    return $rush.next(kit).then( _w => {
      setLetters(Array.from({ length: _w.text.replace(/[\s-]/g,'').length }, () => undefined))
      setWord(_w)
    })
  }
  
  // Init word
  useEffect(() => { nextWord(kit) }, [kit])

  // Finished & Auto Next
  useEffect(() => {
    const _e = $rush.confirm( word, letters, hinted )
    setEnded(_e)
    if( _e === 1 ){
      setTimeout(()=>{ nextWord(kit) }, 200)
      toast('Correct!')
    }
    else if( _e === 2 ) toast('Failed!','error')
  }, [word, letters, kit, hinted])
  
  // Hit `Space` to hint
  useEvent('keydown', e =>{
    if( word && e.code === 'Space' ){
      // Next
      if( ended === 2 ) nextWord(kit)
      // Hint
      else{
        const hinted = $rush.hintLetter( letters.slice(), word.text.replace(/[\s-]/g,'').split('') )
        setLetters( hinted )
        setHinted(true)
      }
    }
  },[word,letters,kit])

  // console.log( word?.text, letters, ended )
  
  return !word ? <Loading /> : (
    <Ani className='container center'>
      
      {/* Inputer */}
      <section className='my-50 center'>
        {/* Word Defs */}
        <div className='left mt-30' style={{ maxWidth:'360px', margin:'0 auto' }}>{
          word.trans?.defs && Object.keys( word.trans.defs ).map( (_pos,_i) =>
            <div key={_pos+_i} className='border-b border-ink mt-20 flex-row'>
              <div className='flex-1'>
                { word.trans.defs[_pos].map( (_w,_i) =>
                  <span key={_w+_i} className='th-24 h-24 t-6 round-full px-10 center mr-10'>{ _w }</span> 
                )}
              </div>
              <p className='primary bold t-6 th-24 flex-none'>.{_pos.toLocaleLowerCase()}</p>
            </div>
          )
        }</div>
        <div className='mt-50'>
          <InputLetters key={ word.text } letters={ letters } onChange={ _ls => !ended && setLetters(_ls) } />
        </div>
        { !!ended &&
          <p className='center mt-20 bold primary'>{ word.text.replace(/[\s-]/g,'').split('') }</p>
        }
      </section>

    </Ani>
  )
}


// const WordDefs = ( word, ended, letters ) => (
//   <section>
    
//     {/*  Related */}
//     <div className='center h-32'>
//       { word.net.related.map( (_w,_i) =>
//         <span key={_w+_i} className='inline mr-10 th-32 h-32 t-5 round-full bg-ink px-10 tapable border-lighten' onClick={ e=> openWordBox( _w, e.target ) }>{ _w }</span> 
//       )}
//     </div> 

//     {/* Synonyms & Antonyms */}
//     <div className='grid grid-cols-2 gap-10 left mt-30'>
//       <div className='p-20 border-lighten bg-ink round'>
//         <p className='t-6 bold primary mb-10'>Synonyms</p>
//         {
//           word?.net?.synonyms?.map( (_w,_i) =>
//             <span key={'synonyms'+_w+_i} className='inline mr-10 tapable' onClick={ e=> openWordBox( _w, e.target ) }>{ _w }</span> 
//           )
//         }
//       </div>
//       <div className='p-20 border-lighten bg-ink round'>
//         <p className='t-6 bold primary mb-10'>Antonyms</p>
//         {
//           word?.net?.antonyms?.map( (_w,_i) =>
//             <span key={'antonyms'+_w+_i} className='inline mr-10 tapable' onClick={ e=> openWordBox( _w, e.target ) }>{ _w }</span> 
//           )
//         }
//       </div>
//     </div>
//   </section>
// )

export default VocabRush